.row-header{
  margin:0px auto;
  padding:0px auto;
}

.row-content {
  margin:0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height:400px;
}

.footer{
  background-color: #EDEDED;
  margin:0px auto;
  padding: 20px 0px 20px 0px;
}

address{
  font-size:80%;
  margin:0px;
  color:#0f0f0f;
}

.jumbotron {
  padding:70px 30px 70px 30px;
  margin:0px auto;
  background: #00AFF5 ;
  color:floralwhite;
}



.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}


.homeBackground{
  position: relative;
  background-image: url(/assets/images/backgroundHome.png);
  height: 530px;
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  width: 100%;
}
.home-text{
  position:absolute ;
  margin-top: 5%;
  top: 0; left: 0; bottom: 0; right: 0;
}



.fa-plane{
  color :#00AFF5
 }
 .fa-car{
    color : #00AFF5
 }
 .fa-train{
   color : #00AFF5 
 }
 .fa-bus{
  color : #00AFF5 
}

@media all and (min-width: 480px) {
  .Login {
    padding: 40px 0;
  }

  .Login Form {
    margin: 0 auto;
    max-width: 400px;
  } 
}

.FacebookClass{
  color: white;
  font-size: large;
  font-style: oblique;
  line-height: 15px;
  border-width: 0px;
  border-radius: 25px;
  padding: 12px;
  background-color:  #3b5998;
}
.FacebookButton {
  background-color:  #3b5998;

}


.GoogleClass{
  color: black;
  font-size: large;
  font-style: oblique;
  line-height: 15px;
  border-width: 0px;
  padding: 10px;
  border-radius: 25px;
  background-color: white;

}

.form-control:focus{
  outline:0;
  box-shadow:none;
  background-color: rgb(237, 237, 237);
  border: 1mm solid  #00AFF5 ;
}
.form-control  {
  background-color: rgb(237, 237, 237);
  width: 100%;
  border-radius: 20px;
  padding: 25px;
  padding-left: 2rem;
}


select {
  outline:0;
  word-wrap: normal;
  background-color: rgb(237, 237, 237);
  width: 100%;
  border-radius: 20px;
  padding: 0px 25px 0px 25px;
  height: 50px;
  border: 1mm solid  #00AFF5 ;
}

.inheritLinkColor:hover{
  color: inherit;
    text-decoration: inherit;
}

.inheritLinkColor{
  color: inherit;
  text-decoration: inherit;
}
                                 
.btn-primary{
  line-height: 15px;
  border-width: 0px;
  border-radius: 25px;
  padding: 15px; 
}

.btn-header{
  border-radius: 15px;
  padding: 8px; 
}

btn-group-sm>.btn, .btn-sm{
  border-radius: 15px;
  padding: 7px;
  font-size: large;
  background-color: rgb(237, 237, 237);
}

.card {
  border: 1px solid rgba(0,0,0,.125); 
  border-radius: 15px;
  box-shadow: 5px 5px 5px #383d416b;
}

@media only screen and (max-width: 515px) {
  .react-datepicker__month-container {
    float: none;
  }
}
@media only screen and (max-width: 515px) {
  .react-datepicker__month-container {
    float: none;
  }
}

@media only screen and (min-width: 769px) and (max-width : 1200px) {
  .react-datepicker__month-container {
    float: none;
  }
}

.btn-primary {
  color : white;
  background-color: #00AFF5 ;
  border-color: #00AFF5 ;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #00AFF5 ;
  border-color: #00AFF5 ;
}

.text-primary {
  color: #00AFF5 !important;
}
.text-secondary {
  color: #283b82  !important;
}

.btn-link {
  font-weight: 400;
  color: #00AFF5;
  text-decoration: none;
}

.errors >span {
  color: red;
  display: block;
}

.modal__header {
    height: 100px;
}

.modal__footer {
  height: 70px;
}

.modal__content {
  position: absolute;
  bottom: 75px;
  top: 175px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  right: 0;
}
.modal__footer {
  bottom: 0;
}

sup {
  position: absolute; 
  top: 04px;
  left: 37px;
  font-size: 90%;
  line-height: 0;
  vertical-align: baseline;
}


.badgeNotificationRelative{
  position: relative; 
}

.navbar {
  border-bottom: solid 1px #EBEBEB;
}
.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
  background-color: white;
  padding: 0px 10px;  
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
}

.bottom-nav-link.active {
  color: #00AFF5;

}
.bottom-tab-label {
  font-size: 12px;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #00AFF5;
}

.localisationCity{
  position: absolute;
  bottom: 0.4em;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  color: #aaa;
}

.mobile-app-link{
text-align: center;
display: inline-table;
vertical-align: middle;
margin: 0 10px 20px 0;
}

.mobile-app-span{
display: table-row;
}

/******* Sidebar CSS ******/
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 3px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  top: 20px;
  left:20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #818288;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}



/* General sidebar styles */
.bm-menu {
  background: rgb(255, 255, 255);
  padding: 1.9em 1.5em 0;
  font-size: 1.10em;
  margin-top: 0px;
  margin-left: 0px !important;
  margin-right: 20px;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
  color: #ffffff;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(247, 246, 246);
  margin-top: 0px;

}

*:focus {
  outline: none;
}

.stop-over-btn {
  background: white;
} 	
